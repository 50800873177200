import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import animate from 'animate.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import normalize from 'normalize.css'
import '../assets/scss/main.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            socialLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div className={'container full-height'}>
          <div className={'vertical-align'}>
            <div className={'animated fadeInUp'}>
              {children}
              <footer>
                <div className={'social-menu clearfix'}>
                  <a href={'https://www.facebook.com/therhyssaunders'}>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href={'https://www.twitter.com/therhyssaunders'}>
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href={'https://www.linkedin.com/in/therhyssaunders/'}>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  <a href={'mailto:rhyssaunders90@gmail.com'}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                  <a href={'https://github.com/Rhys90'}>
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
