import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import rhys from '../images/rhys.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[]} />
      <img class={'avatar'} src={rhys} alt="Rhys Saunders" />
      <h1>RhysSaunders.com</h1>
      <h2>Under Construction</h2>
  </Layout>
)

export default IndexPage
